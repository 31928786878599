//TODO: decide whether to use this style of initialization tracking, or the
//way outlined in the queue controllers & jobs.tracker.service.js
angular.module('sq.jobs.results.service', [])
.factory('resultState', resultStateFactory);

function resultStateFactory() {
  const rS = {
    // This is used just as a singleton state holder, no validation done/needed
    _id : null,
  };
  
  let _searchMode = false;

  rS.toggleSearchMode = (value) => {
    if(value !== undefined) {
      _searchMode = !!value;
    } else {
      _searchMode = !_searchMode;
    }
  };

  Object.defineProperty(rS, 'searchMode', {
    get: () => _searchMode,
  });

  rS.clearJobBeingViewed = () => {
    _searchMode = false;
  };

  return rS;
}